<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col md:w-1/5 mt-4">
            <label class="text-sm">Date Debut</label>
            <flat-pickr v-model="DateDebut" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateDebut" />
          </div>
          <div class="vx-col md:w-1/5 mt-4">
            <label class="text-sm">Date Fin</label>
            <flat-pickr v-model="DateFin" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateFin" />
          </div>
          <div class="vx-col md:w-1/5 mt-4">
            <vs-select v-if="principale" label="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine" @change="onSelectOfficine(idOfficine)">
              <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
            </vs-select>
          </div>
          <div class="vx-col md:w-1/5 mt-10">
            <vs-button ref="loadableButton" id="button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-search" @click="getDepenseOnPeriode()">Valider</vs-button>
          </div>
          <div class="vx-col md:w-1/5 mt-10">
            <vs-button id="pdf-button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-printer" @click="printDepensePdf()">Imprimer</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
    <div  class="vx-col w-full mt-5">
      <vx-card id="corps-proforma">
        <!-- PDF Generator -->

        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="filename"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="landscape"
          pdf-content-width="95%"

          ref="html2Pdf"
        >
          <section slot="pdf-content" class="ml-12">
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <h5 class="underline"> {{officineName}} </h5>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-1/3 mt-base">

                  </div>
                  <div class="vx-col w-1/3 mt-base text-center">
                    <div class="titre_etat_regl p-1">
                      <h3>Opération de caisse Dépense</h3>
                    </div>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-full text-center">
                    <h4>Période du {{DateDebut | moment("calendar", "July 10 2011") }} au {{DateFin | moment("calendar", "July 10 2011") }}</h4>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <!-- {{reglements_client}} -->
                  <table class="w-full customtable" cellpadding="0" cellspacing="0">
                    <thead>
                      <tr>
                        <th class="customth" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">Date</th>
                        <th class="customth" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">Heure</th>
                        <th class="customth" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">Moyen</th>
                        <th class="customth" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">N°Chèq</th>
                        <th class="customth" colspan="2" style="border-bottom-width: 0px; border-right-width: 0px;">Motif</th>
                        <th class="customth" colspan="2" style="border-bottom-width: 0px; border-right-width: 0px;">Caissier</th>
                        <th class="customth" colspan="2" style="border-bottom-width: 0px; border-right-width: 0px;">Bénéficiaire</th>
                        <th class="customth" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">Entrée</th>
                        <th class="customth" colspan="1" style="border-bottom-width: 0px;">Sortie</th>
                      </tr>
                    </thead>
                    <tbody v-if="depenses.length >0">
                      <template v-for="(tr, indextr) in getDepenseAndAppro">
                        <tr :key="indextr">
                          <td class="customtd" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">{{tr.DateOp | moment("calendar", "July 10 2011") }} </td>
                          <td class="customtd" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">{{tr.createdAt | moment("HH:mm:ss") }} </td>
                          <td class="customtd" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">{{tr.MoyPaiement}}</td>
                          <td class="customtd" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;"> {{tr.NumCheque}} </td>
                          <td class="customtd" colspan="2" style="border-bottom-width: 0px; border-right-width: 0px;">{{tr.Motif}}</td>
                          <td class="customtd" colspan="2" style="border-bottom-width: 0px; border-right-width: 0px;">{{getUserById(tr.IdUser).nom}} {{getUserById(tr.IdUser).prenoms}}</td>
                          <td class="customtd" colspan="2" style="border-bottom-width: 0px; border-right-width: 0px;">{{tr.Beneficiaire}}</td>
                          <td class="text-right customtd" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">
                            <template v-if="tr.Origine">
                              {{moneyFormatter(tr.Montant ? tr.Montant : 0)}}
                            </template>
                          </td>
                          <td class="text-right customtd" colspan="1" style="border-bottom-width: 0px;">
                            <template v-if="tr.Origine">
                            </template>
                            <template v-else>
                              {{moneyFormatter(tr.Montant ? tr.Montant : 0)}}
                            </template>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="customtd" colspan="10" style="border-bottom-width: 0px; border-right-width: 0px;">
                          <h6>TOTAL</h6>
                        </td>
                        <td class="customtd text-right text-primary" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">
                          {{(moneyFormatter((+(getFirstInitialisation ? (getFirstInitialisation.Montant ? getFirstInitialisation.Montant : 0) : 0) + +totalEntree)))}}
                        </td>
                        <td class="customtd text-right text-danger" colspan="1" style="border-bottom-width: 0px;">
                          {{moneyFormatter(totalSortie)}}
                        </td>
                      </tr>
                      <tr>
                        <td class="customtd" colspan="10" style="border-right-width: 0px;">
                          <h6>SOLDE</h6>
                        </td>
                        <td class="customtd" colspan="1" style="border-right-width: 0px;border-left-width: 0px;"></td>
                        <td class="customtd text-right" colspan="1" style="border-left-width: 0px;">
                          <template v-if="(solde >= 0)">
                            <span class="text-primary">
                              {{ moneyFormatter(solde) }}
                            </span>
                          </template>
                          <template v-else>
                            <span class="text-danger">
                              {{ moneyFormatter(solde) }}
                            </span>
                          </template>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </vue-html2pdf>

        <!-- PDF Generator end -->
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="vx-row">
              <div class="vx-col w-full">
                <h5 class="underline"> {{officineName}} </h5>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-1/3 mt-base">

              </div>
              <div class="vx-col w-1/3 mt-base text-center">
                <div class="titre_etat_regl p-1">
                  <h3>Opération de caisse Dépense</h3>
                </div>
              </div>
            </div>
            <div class="vx-row mt-5">
              <div class="vx-col w-full text-center">
                <h4>Période du {{DateDebut | moment("calendar", "July 10 2011") }} au {{DateFin | moment("calendar", "July 10 2011") }}</h4>
              </div>
            </div>
            <div class="vx-row mt-5">
              <!-- {{reglements_client}} -->
              <table class="w-full customtable" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th class="customth" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">Date</th>
                    <th class="customth" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">Heure</th>
                    <th class="customth" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">Moyen</th>
                    <th class="customth" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">N°Chèq</th>
                    <th class="customth" colspan="2" style="border-bottom-width: 0px; border-right-width: 0px;">Motif</th>
                    <th class="customth" colspan="2" style="border-bottom-width: 0px; border-right-width: 0px;">Caissier</th>
                    <th class="customth" colspan="2" style="border-bottom-width: 0px; border-right-width: 0px;">Bénéficiaire</th>
                    <th class="customth" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">Entrée</th>
                    <th class="customth" colspan="1" style="border-bottom-width: 0px;">Sortie</th>
                  </tr>
                </thead>
                <tbody v-if="depenses.length >0">
                  <template v-for="(tr, indextr) in getDepenseAndAppro">
                    <tr :key="indextr">
                      <td class="customtd" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">{{tr.DateOp | moment("calendar", "July 10 2011") }} </td>
                      <td class="customtd" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">{{tr.createdAt | moment("HH:mm:ss") }} </td>
                      <td class="customtd" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">{{tr.MoyPaiement}}</td>
                      <td class="customtd" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;"> {{tr.NumCheque}} </td>
                      <td class="customtd" colspan="2" style="border-bottom-width: 0px; border-right-width: 0px;">{{tr.Motif}}</td>
                      <td class="customtd" colspan="2" style="border-bottom-width: 0px; border-right-width: 0px;">{{getUserById(tr.IdUser).nom}} {{getUserById(tr.IdUser).prenoms}}</td>
                      <td class="customtd" colspan="2" style="border-bottom-width: 0px; border-right-width: 0px;">{{tr.Beneficiaire}}</td>
                      <td class="text-right customtd" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">
                        <template v-if="tr.Origine">
                          {{moneyFormatter(tr.Montant ? tr.Montant : 0)}}
                        </template>
                      </td>
                      <td class="text-right customtd" colspan="1" style="border-bottom-width: 0px;">
                        <template v-if="tr.Origine">
                        </template>
                        <template v-else>
                          {{moneyFormatter(tr.Montant ? tr.Montant : 0)}}
                        </template>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="customtd" colspan="10" style="border-bottom-width: 0px; border-right-width: 0px;">
                      <h6>TOTAL</h6>
                    </td>
                    <td class="customtd text-right text-primary" colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">
                      {{(moneyFormatter((+(getFirstInitialisation ? (getFirstInitialisation.Montant ? getFirstInitialisation.Montant : 0) : 0) + +totalEntree)))}}
                    </td>
                    <td class="customtd text-right text-danger" colspan="1" style="border-bottom-width: 0px;">
                      {{moneyFormatter(totalSortie)}}
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" colspan="10" style="border-right-width: 0px;">
                      <h6>SOLDE</h6>
                    </td>
                    <td class="customtd" colspan="1" style="border-right-width: 0px;border-left-width: 0px;"></td>
                    <td class="customtd text-right" colspan="1" style="border-left-width: 0px;">
                      <template v-if="(solde >= 0)">
                        <span class="text-primary">
                          {{ moneyFormatter(solde) }}
                        </span>
                      </template>
                      <template v-else>
                        <span class="text-danger">
                          {{ moneyFormatter(solde) }}
                        </span>
                      </template>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
/* lazy loading Vue components */
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
const VueHtml2pdf = () => import('vue-html2pdf')

export default {
  components: {
    flatPickr,
    VueHtml2pdf
  },
  data () {
    return {
      activeUserInfos: null,
      DateDebut: null,
      DateFin: null,
      idOfficine: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null,
      filename: '',
      officineName: ''
    }
  },
  computed: {
    officines () {
      return this.$store.state.officine.officines
    },
    depenses () {
      return this.$store.state.etat.caisse_depenses
    },
    appros () {
      return this.$store.state.etat.appro_depenses
    },
    getDepenseAndAppro () {
      return this.$store.getters['etat/getDepenseAndAppro']
    },
    principale () {
      return this.$store.getters['officine/officineIsPrincipale'](JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null)
    },
    getFirstInitialisation () {
      const data = this.$store.getters['etat/getDepenseAndAppro']
      return (data && data.length > 0) ? data.find((o) => o.Motif === 'initialisation') : null
    },
    totalEntree () {
      const data = this.$store.getters['etat/getDepenseAndAppro']
      const montant = (data && data.length > 0) ? data.reduce((acc, item) => {
        return acc + +((item.Origine && item.Motif !== 'initialisation') ? (item.Montant ? item.Montant : 0) : 0)
      }, 0) : 0

      return montant
    },
    totalSortie () {
      const data = this.$store.getters['etat/getDepenseAndAppro']
      const montant = (data && data.length > 0) ? data.reduce((acc, item) => {
        return acc + (!item.Origine ? +(item.Montant ? item.Montant : 0) : 0)
      }, 0) : 0
      return montant
    },
    solde () {
      return (+this.totalEntree + +(this.getFirstInitialisation ? (this.getFirstInitialisation.Montant ? this.getFirstInitialisation.Montant : 0) : 0)) - +this.totalSortie
    }
  },
  methods: {
    getUserById (id) {
      return this.$store.getters['userManagement/getUserById'](id)
    },
    printDepensePdf () {

      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#pdf-button-with-loading',
        type: 'sound',
        scale: 0.45
      })

      setTimeout(() => {
        this.$vs.loading.close('#pdf-button-with-loading > .con-vs-loading')
      }, 10000)

      this.$refs.html2Pdf.generatePdf()

    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    onSelectOfficine (id) {
      const officine = this.$store.state.officine.officines.find((o) => o._id === id)
      this.officineName = officine.NomOffic
    },
    getDepenseOnPeriode () {
      const payload = {
        DateDebut: this.DateDebut,
        DateFin: this.DateFin,
        idOfficine: this.idOfficine
      }

      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#button-with-loading',
        type: 'sound',
        scale: 0.45
      })

      this.$store.dispatch('etat/getApproDepenseOnPeriode', payload)
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })

      this.$store.dispatch('etat/getDepenseOnPeriode', payload)
        .then(() => {
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })

    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.onSelectOfficine(JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null)
    },
    fetchUsers () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('userManagement/fetchUsers')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }
  },
  created () {
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    if (!(this.$store.state.userManagement.users.length > 0)) this.fetchUsers()
    this.activeUserInfo()
  }
}
</script>
<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */

.customtable, .customth, .customtd {
  border: 0.5px solid black;
  border-collapse: collapse;
  /* font-family: 'Times New Roman'; */
  font-size: 12px;
}

.customtd {
  padding: 5px;
}
.titre_etat_regl{
  border: 1px solid;
  background-color: #ECF8ED;
}
</style>
